import { zodResolver } from '@hookform/resolvers/zod'
import { randomUUID, UUID } from 'crypto'
import { MessageSquareMore } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { Button } from '../../@/components/ui/button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../../@/components/ui/card'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '../../@/components/ui/dialog'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '../../@/components/ui/form'
import { Input } from '../../@/components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../@/components/ui/select'
import { countries } from '../../utils/country-flag'
import { supabase } from '../../utils/supabase'

const formSchema = z.object({
  title: z.string().min(1),
  content: z.string().min(1),
})

interface ThreadInterface {
  id: UUID,
  user_id: UUID,
  latest_comment_at: string,
  likes: number,
  dislikes: number,
  title: string,
  content: string,
  country: string,
  display_name: string
}

const Forum = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const [isLoggeddIn, setIsLoggedIn] = useState(false)
  const [threads, setThreads] = useState<Array<ThreadInterface>>([])
  const [userId, setUserId] = useState<UUID>()
  const [userName, setUserName] = useState<string>()
  useEffect(() => {
    async function fetchUserSession() {
      try {
        const { data, error } = await supabase.auth.getSession()
        if (data.session !== null) {
          const id = data.session.user.id
          setIsLoggedIn(true)
          setUserId(id as UUID)
          // fetch display name
          const { data: ProfileData, error: ProfileError } = await supabase.from("profiles").select().eq('id', id).maybeSingle()
          setUserName(ProfileData.display_name)
          if (ProfileError) {
            throw ProfileError
          }
        }
        if (error) {
          throw error
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchUserSession()
    async function fetchThreads() {
      try {
        const { data, error } = await supabase.from("threads").select()
        setThreads(data!)
        if (error) {
          throw error
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchThreads()
  }, [])

  function ShillForm() {
    // ...
    const form = useForm<z.infer<typeof formSchema>>({
      resolver: zodResolver(formSchema),

    })

    // 2. Define a submit handler.
    async function onSubmit(values: z.infer<typeof formSchema>) {
      // Do something with the form values.
      // ✅ This will be type-safe and validated.
      const location = await fetch('https://ipapi.co/json/')
      const body = await location.json()
      const res = await supabase.functions.invoke("create-thread", {
        body: {
          title: values.title,
          content: values.content,
          country: body.country_code,
          user_id: crypto.randomUUID(),
          display_name: 'anon'
        }
      })
      if (res.data === "ok") {
        console.log('ok')
      }
      setOpen(false)

    }

    return (

      <Form {...form}>
        {
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full flex flex-col gap-4">
            <div className="flex flex-col w-full gap-4">
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem className='flex flex-col items-center'>
                    <FormLabel className='text-2xl'>Title</FormLabel>
                    <FormControl>
                      <Input placeholder="ex: I Lost All My Money What to Do" {...field} className="h-[50px]" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>

                )}
              />
              <FormField
                control={form.control}
                name="content"
                render={({ field }) => (
                  <FormItem className='flex flex-col items-center'>
                    <FormLabel className='text-2xl'>Content</FormLabel>
                    <FormControl>
                      <Input placeholder="ex: Plz Help" {...field} className="h-[50px]" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>

                )}
              />
            </div>
            <Button type="submit" className='w-full'>Submit</Button>

          </form>

        }


      </Form >
    )
  }
  return (
    <div className="flex flex-col pt-8 items-center justify-evenly gap-8 px-12 max-[768px]:px-4">
      <div className='flex flex-row items-center gap-8'>
        <div className='flex flex-col gap-4'>
          <h1 className='text-3xl font-[600] text-center'>The MoonShills Forum</h1>
          <h2 className='text-center'>Shilling is not allowed on the forum, to promote
            your memecoin you can post in the shilling board or email us to purchase an ad.<br /> for business inquiries, contact us at: moonshills@protonmail.com</h2>
          {
            // isLoggeddIn ?
            (<Dialog open={open} onOpenChange={(e) => setOpen(e)}>
              <DialogTrigger className='w-full'>
                <Button className='w-full py-4' >
                  Post
                </Button>
              </DialogTrigger>
              <DialogContent className='max-[768px]:w-[95%] max-[768px]:rounded-md'>
                <DialogHeader className='flex flex-col items-center justify-evenly h-full gap-4'>
                  <DialogTitle className='flex flex-col items-center'>
                    <span className=' text-3xl'>Post a thread</span>
                  </DialogTitle>
                  <DialogDescription className='w-full flex flex-col gap-4'>
                    <ShillForm />
                  </DialogDescription>
                </DialogHeader>
              </DialogContent>
            </Dialog>)
            // : (
            //   <Link to="/signup" className='flex flex-col w-full py-4 rounded-lg font-[500] text-black text-xl items-center self-center bg-primary'>Sign in to Post a Thread!</Link>
            // )
          }

        </div>
      </div>
      {/* <div>
        <NavigationMenu>
          <NavigationMenuList>
            <NavigationMenuItem>
              <NavigationMenuTrigger>Item One</NavigationMenuTrigger>
              <NavigationMenuContent>
                <NavigationMenuLink>Link</NavigationMenuLink>
              </NavigationMenuContent>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
      </div> */}
      <div className='flex flex-col gap-8 max-[768px]:items-center h-full w-full'>

        {
          threads.map((thread: ThreadInterface) => {
            return (
              <ThreadCard {...thread} />

            )
          })
        }
      </div>
      <footer className='items-center py-4 flex flex-row gap-4 '>
        <p>Copyright © 2024 MoonShills - All rights reserved. </p>
        <Button onClick={() => {
          supabase.auth.signOut()
          navigate("/")
        }}>Sign Out</Button>
      </footer>
    </div >
  )
}


const ThreadCard = (props: ThreadInterface) => {
  const [flag, setFlag] = useState<string>()
  const [commentsNum, setCommentsNum] = useState<number>()
  const navigate = useNavigate()
  useEffect(() => {
    async function fetchCommentsNum() {
      try {
        const { data, error } = await supabase.from("comments").select().eq('id', props.id)
        setCommentsNum(data?.length)
        if (error) {
          throw error
        }
      } catch (error) {
        console.log(error)
      }

      const ting = countries.filter((i) => (
        i.code === props?.country.toLowerCase()
      ))
      setFlag(ting[0].flag)

    }
    fetchCommentsNum()
  }, [])
  return (
    <Card className='flex flex-col w-full items-center cursor-pointer' onClick={() => {
      navigate(`/discussions/${props.id}`)
    }}>
      <CardHeader className='flex flex-col items-center'>
        <CardTitle>
          {props.title}
        </CardTitle>
        <CardDescription className='flex flex-row gap-2 items-center'>
          <h2>post by {props.display_name}</h2>  <img className='h-[50px]' src={flag} />
        </CardDescription>
      </CardHeader>
      <CardContent>
        {props.content}
      </CardContent>
      <CardFooter>
        <div>
        </div>
        <div className='flex flex-row gap-2'>
          <MessageSquareMore />
          {commentsNum}
        </div>
      </CardFooter>
    </Card>
  )
}

export default Forum