import { UUID } from 'crypto'
import { SendHorizontal } from 'lucide-react'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../@/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../@/components/ui/card'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '../../@/components/ui/dialog'
import { supabase } from '../../utils/supabase'

interface ShillInterface {
  id: UUID,
  name: string,
  ticker: string,
  icon: string,
  description: string,
  address: string,
  chain: string,
  dateLaunched: string
}

const Shill = () => {
  let params = useParams();
  const [shill, setShill] = useState<ShillInterface>()
  const [comments, setComments] = useState<Array<any>>()
  const [userId, setUserId] = useState<UUID>()
  const [displayName, setDisplayName] = useState<string>()
  const [commentInput, setCommentInput] = useState<string>()
  const [displayNameInput, setDisplayNameInput] = useState<string>()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const NameColorArr = ["text-green-400", "text-yellow-400", "text-red-400", "text-blue-400", "text-purple-400"]
  const formRef = useRef<HTMLFormElement>(null)
  const navigate = useNavigate()
  useEffect(() => {
    async function fetchUserSession() {
      try {
        const { data, error } = await supabase.auth.getSession()
        if (data.session !== null) {
          const id = data.session.user.id
          setIsLoggedIn(true)
          setUserId(id as UUID)
          // fetch display name
          const { data: ProfileData, error: ProfileError } = await supabase.from("profiles").select().eq('id', id).maybeSingle()
          setDisplayName(ProfileData.display_name)
          if (ProfileError) {
            throw ProfileError
          }
        }
        if (error) {
          throw error
        }
      } catch (error) {
        console.log(error)
      }

    }
    fetchUserSession()
  }, [])
  useEffect(() => {
    async function fetchData() {
      try {
        const { data, error } = await supabase.from("shills").select().eq('id', params.shillId).single()
        setShill(data)

        const { data: commentsData, error: commentsError } = await supabase.from("comments").select().eq('shill_id', params.shillId)
        if (commentsError) {
          throw commentsError
        }
        setComments(commentsData)
        if (error) {
          throw error
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])
  return (
    <div className='flex flex-col items-center w-full py-8'>
      <header className='flex flex-row items-center justify-center'>
        <Link to="/" className='text-4xl font-[600]'>MoonShills</Link>
      </header>
      <div className='flex flex-col p-12 max-[768px]:p-0 w-full items-center min-h-[100dvh] gap-4  max-[768px]:w-[95%]'>
        <Card className='flex flex-row px-6 max-[768px]:px-0 justify-between max-[768px]:w-[90%] w-full' >

          <div className='flex flex-col w-full'>
            <CardHeader className='flex flex-col items-center w-full'>

              <div className=' flex flex-row items-center justify-between w-full'>
                <div className='flex flex-col gap-0'>
                  <CardTitle className='h-full flex flex-col gap-4'>
                    <div>

                      <span className='text-2xl max-[768px]:text-lg'>{shill?.name}&nbsp;&nbsp;</span>
                      <span className='text-lg max-[768px]:text-sm text-zinc-400'>{shill?.ticker}</span>
                    </div>
                    <span className='text-lg max-[768px]:text-sm text-zinc-400'>created on: {shill?.dateLaunched}</span>

                  </CardTitle>
                </div>

                <img src={shill?.icon} className="h-[75px] max-[768px]:h-[50px] rounded-full" />
              </div>

            </CardHeader>

            <CardContent className='flex flex-col gap-4'>
              <CardDescription className='text-xl max-[768px]:text-[14px]'>{shill?.chain}: <p className=' break-words'>{shill?.address}</p></CardDescription>

              <p className='max-[768px]:text-sm'>{shill?.description}</p>
            </CardContent>
          </div>

        </Card>
        {
          isLoggedIn ? (displayName ? (
            <form onSubmit={(e) => {
              e.preventDefault()
              setCommentInput(undefined)
              formRef.current?.reset()
            }}
              ref={formRef}
              className="w-full"
            >
              <label className="input input-bordered w-full flex items-center gap-2 bg-primary-foreground">
                <input type="text" className="w-full" placeholder={`Comment as ${displayName}`} onChange={(e) => {
                  setCommentInput(e.target.value)
                }} />
                <button type='submit' onClick={() => {
                  console.log(commentInput)
                  supabase.functions.invoke("post-comment", {
                    body: {
                      user_id: userId,
                      text: commentInput,
                      shill_id: shill?.id,
                      user_name: displayName,
                      color: NameColorArr[Math.floor(Math.random() * NameColorArr.length)]
                    }
                  })
                }}>
                  <SendHorizontal className=' hover:text-white hover:bg-slate-500 rounded-full p-1 w-8 h-8' />
                </button>
              </label>
            </form>

          ) : (
            <Dialog>
              <DialogTrigger className='w-full'>
                <Button className='w-full break-words max-[768px]:w-[90%] py-4 p-2 h-full'>Click here to set your display<br className='hidden max-[768px]:block' /> name to start commenting!</Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader className='flex flex-col items-center gap-4'>
                  <DialogTitle className='text-2xl'>Set your display name:</DialogTitle>
                  <DialogDescription>
                    <form onSubmit={(e) => {
                      e.preventDefault()
                      supabase.functions.invoke("update-display-name", {
                        body: {
                          id: userId,
                          display_name: displayNameInput
                        }
                      })
                    }}>
                      <input type="text" name='name' className='input input-bordered w-full bg-primary-foreground' onChange={(e) => {
                        setDisplayNameInput(e.target.value)
                      }} />
                    </form>
                  </DialogDescription>
                </DialogHeader>
              </DialogContent>
            </Dialog>
          )) : (
            <Link to="/signup" className='flex flex-col w-full py-4 rounded-lg text-black text-xl font-[500] items-center self-center bg-primary'>Sign in to start commenting</Link>
          )

        }
        {
          comments?.map((comment) => (
            <Card className='flex flex-col w-full max-[768px]:w-[90%] gap-2 items-start'>
              <CardHeader>
                <h1 className={`${comment.color}`}>{comment.user_name}</h1>
                <CardDescription className='text-xl'>{comment.text}</CardDescription>
              </CardHeader>
            </Card>
          ))
        }
      </div>

      <footer className='items-center py-4 justify-center w-full flex flex-row gap-4 max-[768px]:flex-col '>
        <p className='text-center'>Copyright © 2024 MoonShills - All rights reserved. </p>
        <Button onClick={() => {
          supabase.auth.signOut()
          navigate("/")
        }}>Sign Out</Button>
      </footer>
    </div>
  )
}

export default Shill

