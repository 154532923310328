import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Dashboard';
import { ThemeProvider } from './@/components/ThemeProvider';
import TermsOfService from './pages/TermsOfService';
import Shill from './pages/Shill/Shill';
import Forum from './pages/Forum/Forum';
import Discussion from './pages/Discussion/Discussion';
import Main from './pages/main/Main';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/signup' element={<App />}>
          </Route>
          <Route path='/' element={<Main />}>
          </Route>
          <Route path='/shills' element={<Dashboard />}>
          </Route>
          <Route path="/shills/:shillId" element={<Shill />}>
          </Route>
          <Route path='/terms' element={<TermsOfService />}>
          </Route>
          <Route path='/discussions' element={<Forum />}>
          </Route>
          <Route path="/discussions/:discussionId" element={<Discussion />}>
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
