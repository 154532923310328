import { Ellipsis, MessageSquareMore, SendHorizontal } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { Button } from '../../@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../@/components/ui/card"
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '../../@/components/ui/dialog'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../@/components/ui/dropdown-menu'
import { Textarea } from '../../@/components/ui/textarea'
import { supabase } from '../../utils/supabase'
import '../../index.css'
import { Moon, Sun } from "lucide-react"
import { useTheme } from "../../@/components/ThemeProvider"
import { UUID } from 'crypto'
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../@/components/ui/form"
import { Input } from "../../@/components/ui/input"
import { Link, useNavigate } from 'react-router-dom'
import { NavigationMenu, NavigationMenuContent, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, NavigationMenuTrigger } from '../../@/components/ui/navigation-menu'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../@/components/ui/select'
const formSchema = z.object({
  name: z.string().min(1),
  ticker: z.string().min(1),
  icon: z.string().min(1),
  description: z.string().min(20),
  address: z.string().min(20),
  chain: z.string().min(1),
  dateLaunched: z.string().regex(new RegExp("^([0-2][0-9]||3[0-1])/(0[0-9]||1[0-2])/([0-9][0-9])?[0-9][0-9]$"))

})
interface ShillInterface {
  id: UUID,
  name: string,
  ticker: string,
  icon: string,
  description: string,
  address: string,
  chain: string,
  dateLaunched: string
}


export function ModeToggle() {
  const { setTheme } = useTheme()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon" className='max-[768px]:hidden'>
          <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
          <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => setTheme("light")}>
          Light
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme("dark")}>
          Dark
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme("system")}>
          System
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}


const Dashboard = () => {
  const navigate = useNavigate()
  const [shills, setShills] = useState<Array<ShillInterface>>([])
  const [userId, setUserId] = useState<UUID>()
  const [open, setOpen] = useState(false);
  const [clickedShillDetails, setClickedShillDetails] = useState<ShillInterface | null>(null)
  const [isLoggeddIn, setIsLoggedIn] = useState(false)

  const formRef = useRef<HTMLFormElement>(null)
  useEffect(() => {
    async function fetchUserSession() {
      try {
        const { data, error } = await supabase.auth.getSession()
        if (data.session !== null) {
          const id = data.session.user.id
          setIsLoggedIn(true)
          setUserId(id as UUID)
          // fetch display name
          const { data: ProfileData, error: ProfileError } = await supabase.from("profiles").select().eq('id', id).maybeSingle()
          if (ProfileError) {
            throw ProfileError
          }
        }
        if (error) {
          throw error
        }
      } catch (error) {
        console.log(error)
      }

    }
    fetchUserSession()
    async function fetchShills() {
      try {

        const { data, error } = await supabase.from("shills").select().eq('approved', true).order('created_at', { ascending: false })
        if (error) {
          throw error
        } else {
          setShills(data)

        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchShills()
  }, [])
  useEffect(() => {
    async function popUpDialog() {
      if (clickedShillDetails !== null) {
        try {
          const { data: commentsData, error: commentsError } = await supabase.from("comments").select().eq('shill_id', clickedShillDetails.id)
          if (commentsError) {
            throw commentsError
          }
          // window.history.pushState({ id: clickedShillDetails.id }, "Page", `/dashboard/${clickedShillDetails.id}`)

        } catch (error) {
          console.log(error)
        }

      } else {
        // window.history.pushState({}, "Page", `/dashboard`)

      }
    }
    popUpDialog()
  }, [clickedShillDetails])
  function ShillForm() {
    // ...
    const form = useForm<z.infer<typeof formSchema>>({
      resolver: zodResolver(formSchema),

    })

    // 2. Define a submit handler.
    async function onSubmit(values: z.infer<typeof formSchema>) {
      // Do something with the form values.
      // ✅ This will be type-safe and validated.

      const res = await supabase.functions.invoke("create-shill", {
        body: {
          user_id: userId,
          name: values.name,
          ticker: values.ticker,
          description: values.description
          , address: values.address,
          chain: values.chain,
          icon: values.icon,
          dateLaunched: values.dateLaunched
        }
      })
      if (res.data === "ok") {
        console.log('ok')
      }
      setOpen(false)

    }

    return (

      <Form {...form}>
        {
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full flex flex-col gap-4">
            <div className="grid grid-flow-row grid-cols-2 w-full gap-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input placeholder="ex: Ethereum" {...field} />
                    </FormControl>
                    <FormDescription>
                      The name of the coin.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>

                )}
              />
              <FormField
                control={form.control}
                name="ticker"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Ticker</FormLabel>
                    <FormControl>
                      <Input placeholder="ex: ETH" {...field} />
                    </FormControl>
                    <FormDescription>
                      The ticker of the coin..
                    </FormDescription>
                    <FormMessage />
                  </FormItem>

                )}
              />
              <FormField
                control={form.control}
                name="icon"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Icon</FormLabel>
                    <FormControl>
                      <Input placeholder="ex: https://...." {...field} />
                    </FormControl>
                    <FormDescription>
                      A URL to the coins icon image..
                    </FormDescription>
                    <FormMessage />
                  </FormItem>

                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Input placeholder="" {...field} />
                    </FormControl>
                    <FormDescription>
                      A description of the coin.(min 20 chars.)
                    </FormDescription>
                    <FormMessage />
                  </FormItem>

                )}
              />

              <FormField
                control={form.control}
                name="address"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Address</FormLabel>
                    <FormControl>
                      <Input placeholder="ex: 0x...." {...field} />
                    </FormControl>
                    <FormDescription>
                      The address of the coin.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>

                )}
              />
              <FormField
                control={form.control}
                name="chain"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Chain</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Chain" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="Ethereum">Ethereum</SelectItem>
                        <SelectItem value="Solana">Solana</SelectItem>
                        <SelectItem value="BNB">BNB</SelectItem>
                        <SelectItem value="Base">Base</SelectItem>
                        <SelectItem value="Avalanche">Avalanche</SelectItem>
                        <SelectItem value="Polygon">Polygon</SelectItem>
                        <SelectItem value="Hedera">Hedera</SelectItem>

                      </SelectContent>
                    </Select>

                    <FormDescription>
                      The chain the coin is on.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>

                )}
              />
              <FormField
                control={form.control}
                name="dateLaunched"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Date Launched</FormLabel>
                    <FormControl>
                      <Input placeholder="ex: 20/03/2024" {...field} />
                    </FormControl>
                    <FormDescription>
                      The launch date of the coin.(DD/MM/YYYY)
                    </FormDescription>
                    <FormMessage />
                  </FormItem>

                )}
              />
            </div>
            <Button type="submit" className='w-full'>Submit</Button>

          </form>

        }


      </Form >
    )
  }


  const ShillCard = (shill: ShillInterface) => {
    const [commentsNum, setCommentsNum] = useState<number>()
    useEffect(() => {
      async function getDaTing() {
        try {
          const { data, error } = await supabase.from("comments").select().eq("shill_id", shill.id)
          console.log(data)
          if (error) {
            throw error
          }
          setCommentsNum(data.length)
        } catch (error) {
          console.log(error)
        }
      }
      getDaTing()
    }, [])
    return (

      <Card className='flex flex-row px-6 max-[768px]:px-0 max-[768px]:w-[85%] cursor-pointer' onClick={() => {
        setClickedShillDetails(shill)
        navigate(`/shills/${shill.id}`)
      }}>

        <div className='flex flex-col w-full h-full'>
          <CardHeader className='flex flex-col items-center w-full'>

            <div className=' flex flex-row items-center justify-between w-full'>
              <div className='flex flex-col gap-0'>
                <CardTitle className='h-full flex flex-col gap-4'>
                  <div>

                    <span className='text-2xl max-[768px]:text-lg'>{shill.name}&nbsp;&nbsp;</span>
                    <span className='text-lg max-[768px]:text-sm text-zinc-400'>{shill.ticker}</span>
                  </div>
                  <span className='text-lg max-[768px]:text-sm text-zinc-400'>created on: {shill.dateLaunched}</span>

                </CardTitle>
              </div>

              <img src={shill.icon} className="h-[75px] max-[768px]:h-[50px] rounded-full" />
            </div>

          </CardHeader>

          <CardContent className='h-full flex flex-col gap-4'>
            <CardDescription className='text-xl h-full w-full max-[768px]:text-[14px]'>{shill.chain}: <p className=' break-words'>{shill.address}</p></CardDescription>
            <div className='flex flex-col'>
              <p className='max-[768px]:text-sm'>{shill.description}</p>
              <div className='flex flex-row gap-2 items-center'><MessageSquareMore />{commentsNum?.toString()}</div>
            </div>
          </CardContent>
        </div>

      </Card>
    )
  }


  return (
    <div className="flex flex-col pt-8 items-center justify-evenly gap-8 px-12 max-[768px]:px-4 overflow-x-hidden">
      <header className='flex flex-row items-center justify-center'>
        <Link to="/" className='text-4xl font-[600] py-4'>MoonShills</Link>
      </header>
      <div className='flex flex-row items-center gap-8'>
        <div className='flex flex-col gap-4'>
          <h1 className='text-3xl font-[600] text-center'>You can browse the most recent shills here! Or shill your own coin with the click of a button!</h1>
          <h2 className='text-center'>Disclaimer: Nothing on this site is financial advice! We(MoonShills.com) are not the creators of any of these coins and
            cannot and will not be responsible for any loss.<br /> for business inquiries, contact us at: moonshills@protonmail.com</h2>
          {
            isLoggeddIn ? (<Dialog open={open} onOpenChange={(e) => setOpen(e)}>
              <DialogTrigger className='w-full'>
                <Button className='w-full py-4' >
                  Shill
                </Button>
              </DialogTrigger>
              <DialogContent className='max-[768px]:w-[95%] max-[768px]:rounded-md'>
                <DialogHeader className='flex flex-col items-center justify-evenly h-full gap-4'>
                  <DialogTitle className='flex flex-col items-center'>
                    <span className=' text-3xl'>Shill Your Coin</span>
                    <span className=' text-xl font-[500] text-zinc-600'>your coin will be listed once approved.</span>
                  </DialogTitle>
                  <DialogDescription className='w-full flex flex-col gap-4'>
                    <ShillForm />
                  </DialogDescription>
                </DialogHeader>
              </DialogContent>
            </Dialog>) : (
              <Link to="/signup" className='flex flex-col w-full py-4 rounded-lg font-[500] text-black text-xl items-center self-center bg-primary'>Sign in to Shill a Coin!</Link>
            )
          }

        </div>
        <ModeToggle />
      </div>
      {/* <div>
        <NavigationMenu>
          <NavigationMenuList>
            <NavigationMenuItem>
              <NavigationMenuTrigger>Item One</NavigationMenuTrigger>
              <NavigationMenuContent>
                <NavigationMenuLink>Link</NavigationMenuLink>
              </NavigationMenuContent>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
      </div> */}
      <div className='flex flex-col gap-8 max-[768px]:items-center h-full overflow-y-scroll'>

        {
          shills.map((shill: ShillInterface) => {
            return (
              <ShillCard {...shill} />

            )
          })
        }
      </div>
      <footer className='items-center py-4 flex flex-row gap-4 '>
        <p>Copyright © 2024 MoonShills - All rights reserved. </p>
        <Button onClick={() => {
          supabase.auth.signOut()
          navigate("/")
        }}>Sign Out</Button>
      </footer>
    </div >
  )
}

export default Dashboard



const ReportDropdown = () => {
  const [reportText, setReportText] = useState<string>()
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Ellipsis size={30} className="mt-6" />
      </DropdownMenuTrigger>
      <DropdownMenuContent align='end'>

        <Dialog>
          <DialogTrigger className='w-full'>
            <Button variant="secondary" className='w-full' onClick={() => {

            }}>Report</Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader className='flex flex-col items-center justify-evenly h-full gap-4'>
              <DialogTitle className=' text-3xl'>Report this Shill</DialogTitle>
              <DialogDescription className='w-full flex flex-col gap-4'>
                <Textarea onChange={(e) => { setReportText(e.target.value) }} className='w-full resize-none h-24' required placeholder="Please give a detailed description. IMPORTANT: false reports may result in a ban!" />
                <Button onClick={() => {
                  console.log(reportText)
                }}>Submit</Button>
              </DialogDescription>
            </DialogHeader>
          </DialogContent>
        </Dialog>

      </DropdownMenuContent>
    </DropdownMenu>
  )
}
