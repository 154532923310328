import { randomUUID, UUID } from 'crypto'
import { MessageSquareMore, SendHorizontal } from 'lucide-react'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../@/components/ui/button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../../@/components/ui/card'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '../../@/components/ui/dialog'
import { supabase } from '../../utils/supabase'
import { countries } from '../../utils/country-flag'
interface ThreadInterface {
  id: UUID,
  user_id: UUID,
  latest_comment_at: string,
  likes: number,
  dislikes: number,
  title: string,
  content: string,
  country: string,
  display_name: string
}


const Discussion = () => {
  let params = useParams();
  const [thread, setThread] = useState<ThreadInterface>()
  const [comments, setComments] = useState<Array<any>>([])
  const [userId, setUserId] = useState<UUID>()
  const [displayName, setDisplayName] = useState<string>()
  const [commentInput, setCommentInput] = useState<string>()
  const [displayNameInput, setDisplayNameInput] = useState<string>()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const NameColorArr = ["text-green-400", "text-yellow-400", "text-red-400", "text-blue-400", "text-purple-400"]
  const formRef = useRef<HTMLFormElement>(null)
  const [flag, setFlag] = useState<string>()
  const navigate = useNavigate()
  useEffect(() => {
    async function fetchUserSession() {
      try {
        const { data, error } = await supabase.auth.getSession()
        if (data.session !== null) {
          const id = data.session.user.id
          setIsLoggedIn(true)
          setUserId(id as UUID)
          // fetch display name
          const { data: ProfileData, error: ProfileError } = await supabase.from("profiles").select().eq('id', id).maybeSingle()
          setDisplayName(ProfileData.display_name)
          if (ProfileError) {
            throw ProfileError
          }
        }
        if (error) {
          throw error
        }
      } catch (error) {
        console.log(error)
      }

    }
    fetchUserSession()
    async function fetchData() {
      try {
        const { data, error } = await supabase.from("threads").select().eq('id', params.discussionId).single()
        setThread(data)

        const { data: commentsData, error: commentsError } = await supabase.from("comments").select().eq('discussion_id', params.discussionId)
        if (commentsError) {
          throw commentsError
        }

        setComments(commentsData)
        if (error) {
          throw error
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    function fetchFlag() {
      const ting = countries.filter((i) => (
        i.code === thread?.country.toLowerCase()
      ))
      setFlag(ting[0].flag)
    }
    fetchFlag()
  }, [thread])
  return (
    <div className='flex flex-col items-center w-full py-8'>
      <header className='flex flex-row items-center justify-center'>
        <Link to="/discussions" className='text-4xl font-[600]'>MoonShills</Link>
      </header>
      <div className='flex flex-col p-12 max-[768px]:p-0 w-full items-center min-h-[100dvh] gap-4  max-[768px]:w-[95%]'>
        <Card className='flex flex-col w-full items-center' >
          <CardHeader className='flex flex-col items-center'>
            <CardTitle>
              {thread?.title}
            </CardTitle>
            <CardDescription className='flex flex-row items-center gap-2'>
              posted by {thread?.display_name} <img src={flag} className="h-[50px]" />
            </CardDescription>
          </CardHeader>
          <CardContent>
            {thread?.content}
          </CardContent>
          <CardFooter>
            <div>
            </div>
            <div className='flex items-center flex-row gap-2'>
              <MessageSquareMore />
              {comments.length}
            </div>
          </CardFooter>
        </Card>
        {
          // isLoggedIn ? 
          // (
          // displayName ? (
          <form onSubmit={(e) => {
            e.preventDefault()
            setCommentInput(undefined)
            formRef.current?.reset()
          }}
            ref={formRef}
            className="w-full"
          >
            <label className="input input-bordered w-full flex items-center gap-2 bg-primary-foreground">
              <input type="text" className="w-full" placeholder={`Comment as ${displayName}`} onChange={(e) => {
                setCommentInput(e.target.value)
              }} />
              <button type='submit' onClick={async () => {
                console.log(commentInput)

                const location = await fetch('https://ipapi.co/json/')
                const body = await location.json()
                const ting = countries.filter((i) => (
                  i.code === body.country_code.toLowerCase()
                ))
                supabase.functions.invoke("post-comment", {
                  body: {
                    user_id: crypto.randomUUID(),
                    text: commentInput,
                    discussion_id: thread?.id,
                    user_name: 'anon',
                    flag_img: ting[0].flag,
                    color: NameColorArr[Math.floor(Math.random() * NameColorArr.length)]
                  }
                })
              }}>
                <SendHorizontal className=' hover:text-white hover:bg-slate-500 rounded-full p-1 w-8 h-8' />
              </button>
            </label>
          </form>

          // ) : (
          // <Dialog>
          //   <DialogTrigger className='w-full'>
          //     <Button className='w-full break-words max-[768px]:w-[90%] py-4 p-2 h-full'>Click here to set your display<br className='hidden max-[768px]:block' /> name to start commenting!</Button>
          //   </DialogTrigger>
          //   <DialogContent>
          //     <DialogHeader className='flex flex-col items-center gap-4'>
          //       <DialogTitle className='text-2xl'>Set your display name:</DialogTitle>
          //       <DialogDescription>
          //         <form onSubmit={(e) => {
          //           e.preventDefault()
          //           supabase.functions.invoke("update-display-name", {
          //             body: {
          //               id: crypto.randomUUID(),
          //               display_name: displayNameInput
          //             }
          //           })
          //         }}>
          //           <input type="text" name='name' className='input input-bordered w-full bg-primary-foreground' onChange={(e) => {
          //             setDisplayNameInput(e.target.value)
          //           }} />
          //         </form>
          //       </DialogDescription>
          //     </DialogHeader>
          //   </DialogContent>
          // </Dialog>
          // )
          // ) 
          // : (
          //   <Link to="/signup" className='flex flex-col w-full py-4 rounded-lg text-black text-xl font-[500] items-center self-center bg-primary'>Sign in to start commenting</Link>
          // )

        }
        {
          comments?.map((comment) => (
            <Card className='flex flex-col w-full max-[768px]:w-[90%] gap-2 items-start'>
              <CardHeader>
                <div className='flex flex-row gap-2 items-center'>
                  <h1 className={`${comment.color}`}>{comment.user_name}</h1>
                  <img className='h-[50px]' src={comment.flag_img} />
                </div>
                <CardDescription className='text-xl'>{comment.text}</CardDescription>
              </CardHeader>
            </Card>
          ))
        }
      </div>

      <footer className='items-center py-4 justify-center w-full flex flex-row gap-4 max-[768px]:flex-col '>
        <p className='text-center'>Copyright © 2024 MoonShills - All rights reserved. </p>
        <Button onClick={() => {
          supabase.auth.signOut()
          navigate("/")
        }}>Sign Out</Button>
      </footer>
    </div>
  )
}

export default Discussion

